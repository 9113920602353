

























































































@import '../../../../utilities/config';

fieldset {
  border-top: 2px solid theme-color('gray');
  margin: 0;
  padding-left: 5px
}

legend {
  margin-left: 4px;
  margin-bottom: 0;
  width: auto;
  padding: 0 5px 0 5px;
}

.line {
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 6px;
  background-color: gray('gray-125');
}

