















































@import '../../utilities/config';

.form__step {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: gray('white');
  border-bottom: 1px solid gray('gray-150');
  &__list {
    display: flex;
    position: relative;

    > :not(:last-child) {
      flex-grow: 1;
    }
  }
}
