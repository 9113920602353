

































































@import '../../../../utilities/config';

fieldset {
  border-top: 2px solid theme-color('gray');
  margin: 0;
  padding: 5px
}

legend {
  margin-left: 4px;
  margin-bottom: 0;
  width: auto;
  padding: 0 5px 0 5px;
}

.address-field {
  @media screen and (max-width: breakpoint-max('lg')) {
    margin-bottom: 5px;
  }
}

.middle-address-field {
  @extend .address-field;
  @media screen and (min-width: breakpoint-min('xl')) {
    padding-right: 3px;
    padding-left: 3px;
  }
}

.left-address-field {
  @extend .address-field;
  @media screen and (min-width: breakpoint-min('xl')) {
    padding-right: 3px;
  }
}

.right-address-field {
  @extend .address-field;
  @media screen and (min-width: breakpoint-min('xl')) {
    padding-left: 3px;
  }
}
