

































@import './src/utilities/config';

.land-owner {
  .form__col {
    margin: 0;
  }
  .radio-group-label {
    display: flex;
    padding-bottom: 8px;
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 500;
    color: gray('gray-700');
    border-bottom: 4px solid gray('gray-150');
  }
}
