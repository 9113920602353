












































































































@import '../../../utilities/config';

.question {
  border-bottom: 4px solid gray('gray-150');
  padding-bottom: 8px;
  margin-top: 25px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .radio-group-label {
    font-size: 21px;
    font-weight: 500;
    color: gray('gray-700');
  }
}

.section {
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 6px;
  background-color: gray('gray-125');

  .radio-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .radio-group-label {
      font-size: unset;
      font-weight: unset;
    }
  }
}

.section-title {
  font-size: 18px;
  font-weight: 500;
  color: gray('gray-700');
  margin-top: 0;
  margin-bottom: 20px;
}
