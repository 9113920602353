






























































@import '../../../../utilities/table/table';

.hidden {
  visibility: hidden;
}

.icons {
  display: flex;
}

.phone-icon {
  width: 18px;
  margin-right: 1px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../../../assets/telephone_gray.svg);
}

.email-icon {
  width: 18px;
  margin-left: 1px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../../../assets/email.svg);
}
